import { DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { getCurrentLocaleCode } from '@mongez/localization';
import { round } from '@mongez/reinforcements';
import { toastError, toastSuccess } from '@presentation/shared/toast';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { combineLatest, Subscription } from 'rxjs';
import { CartProductModel, CartUpdateModel } from 'src/app/core/domain/cart.model';
import { EmptyCartUseCase } from 'src/app/core/usecases/cart/empty-cart.usecase';
import { RemoveProductFromCartUseCase } from 'src/app/core/usecases/cart/remove-product-from-cart.usecase';
import { UpdateCartUseCase } from 'src/app/core/usecases/cart/update-cart.usecase';
import { GetCountryVATByIsoCode3UseCase } from 'src/app/core/usecases/get-country-vat.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { FEATURE_FLAGS } from '../../shared/constants';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { SECOND_IN_PLACEMENT_DISCOUNT } from '../../shared/constants/discounts';
import {
  CART_BANNER_KEY,
  CART_DIALOG_KEY,
  QUANTITY_DISCOUNT_VALUE,
} from '../../shared/constants/quantity-discount';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { MultitenancyService } from '../../shared/services/multitenancy.service';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';
import { CartProductAttributesComponent } from '../cart-product-attributes/cart-product-attributes.component';
import { ConfirmDeleteItemComponent } from '../confirm-delete-item/confirm-delete-item.component';
import { QuantityDiscountBannerComponent } from '../quantity-discount-banner/quantity-discount-banner.component';
import { QuantityDiscountDialogComponent } from '../quantity-discount-dialog/quantity-discount-dialog.component';
import { SecondProductDiscountBannerComponent } from '../second-product-discount-banner/second-product-discount-banner.component';

@Component({
  selector: 'app-cart-products-table',
  templateUrl: './cart-products-table.component.html',
  styleUrls: ['./cart-products-table.component.scss'],
  standalone: true,
  imports: [
    SecondProductDiscountBannerComponent,
    NgClass,
    NgIf,
    NgFor,
    RouterLink,
    CartProductAttributesComponent,
    FormsModule,
    DecimalPipe,
    CurrencyShortNamePipe,
    QuantityDiscountBannerComponent,
  ],
})
export class CartProductsTableComponent extends LocalizedComponent implements OnInit {
  public shouldShowDiscountAsSecondProduct = false;

  public discountAsSecondProductApplied = false;

  public shouldShowVAT = false;

  public isResizeImageEnabled = false;

  public currency: string;

  public languageChangeSubscription: Subscription;

  @Input() products: Array<CartProductModel>;

  @Input() shouldShowQuantityDiscount: boolean;

  @Output() cartProductsUpdated = new EventEmitter<void>();

  @Output() quantityChanged = new EventEmitter<void>();

  public math = Math;

  public bannerEnabled = true;

  public dialogEnabled = true;

  public shouldShowStockDistribution = false;

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getCountryVATByIsoCode3UseCase: GetCountryVATByIsoCode3UseCase,
    public _multitenancyService: MultitenancyService,
    private _dialog: MatDialog,
    private _removeProductFromCartUseCase: RemoveProductFromCartUseCase,
    private _emptyCartUseCase: EmptyCartUseCase,
    private _updateCartUseCase: UpdateCartUseCase,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
    private _localStorageService: LocalStorageService,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    super();
  }

  ngOnInit(): void {
    this._fetchFeatureFlags();
    this._getIsDiscountAsSecondProductApplied();
    this.dialogDisplayCheck();
    this._getIsVatShown();
    this._getCurrency(getCurrentLocaleCode());
    this._handleLanguageChange();
    this.bannerDisplayCheck();
    this.showQuantityDiscountDialog();
  }

  private _fetchFeatureFlags(): void {
    const shouldShowDiscountAsSecondProduct$ = this._getFeatureFlagUseCase.execute(
      FEATURE_FLAGS.DISCOUNTED_AS_SECOND_PRODUCT,
    );

    const isResizeImageEnabled$ = this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.RESIZE_IMAGES);

    combineLatest([shouldShowDiscountAsSecondProduct$, isResizeImageEnabled$]).subscribe({
      next: ([shouldShowDiscountAsSecondProduct, isResizeImageEnabled]) => {
        this.shouldShowDiscountAsSecondProduct = shouldShowDiscountAsSecondProduct;
        this.isResizeImageEnabled = isResizeImageEnabled;
      },
    });

    this.isStockDistributionEnabled();
  }

  public isStockDistributionEnabled(): void {
    this._getFeatureAttributeUseCase
      .execute(FEATURE_FLAGS.STOCK_DISTRIBUTION)
      .subscribe((attribute) => {
        this.shouldShowStockDistribution = featureAttributeAssign(attribute, user.id);
      });
  }

  private _getIsDiscountAsSecondProductApplied(): void {
    this.discountAsSecondProductApplied = this.products.some(
      (product) =>
        this.shouldShowDiscountAsSecondProduct &&
        product.appliedDiscount.type === SECOND_IN_PLACEMENT_DISCOUNT,
    );
  }

  private _getIsVatShown(): void {
    this._getCountryVATByIsoCode3UseCase.execute(country.code).subscribe({
      next: (countryVatAmount) => {
        this.shouldShowVAT = countryVatAmount > 0;
      },
    });
  }

  private _handleLanguageChange(): void {
    this._getCurrency(getCurrentLocaleCode());
  }

  private _getCurrency(selectedLanguage: string): void {
    const isEnglishLanguage = selectedLanguage === ENGLISH_LANGUAGE;
    const currencyObject = this._multitenancyService.getCurrentCountry().currency;
    this.currency = isEnglishLanguage ? currencyObject.englishName : currencyObject.arabicName;
  }

  public deleteAllProducts(): void {
    const dialogRef = this._dialog.open(ConfirmDeleteItemComponent, {
      width: '460px',
      data: {
        isBulkDelete: true,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (isDeletionConfirmed) => {
        if (isDeletionConfirmed) {
          this._handleAllProductsDeletion();
        }
      },
    });
  }

  private _handleAllProductsDeletion(): void {
    this._emptyCartUseCase.execute(this.products.map((product) => product.id)).subscribe({
      error: () => {
        toastSuccess(this.trans('CART.TRY_AGAIN_LATER'));
      },
      complete: () => {
        toastSuccess(this.trans('CART.ALL_PRODUCTS_DELETED'));
        this.cartProductsUpdated.emit();
      },
    });
  }

  public onDeleteProductClicked(id: string): void {
    const dialogRef = this._dialog.open(ConfirmDeleteItemComponent, {
      width: '460px',
    });

    dialogRef.afterClosed().subscribe({
      next: (isDeletionConfirmed) => {
        if (isDeletionConfirmed) {
          this._handleSingleProductDeletion(id);
        }
      },
    });
  }

  private _handleSingleProductDeletion(id: string): void {
    this._removeProductFromCartUseCase.execute(id).subscribe({
      next: () => {
        toastSuccess(this.trans('CART.PRODUCT_DELETED'));
        this.cartProductsUpdated.emit();
      },
      error: () => {
        toastError(this.trans('CART.TRY_AGAIN_LATER'));
      },
    });
  }

  public onDesiredPriceChange(): void {
    const cartProducts: CartUpdateModel = {
      products: this.products.map((product) => ({
        productId: product.prodId,
        quantity: product.qty,
        desiredPrice: product.desiredPrice,
      })),
    };
    this._updateCartUseCase.execute(cartProducts).subscribe({
      next: () => {
        this.cartProductsUpdated.emit();
      },
      error: () => {
        toastError(this.trans('CART.TRY_AGAIN_LATER'));
      },
    });
  }

  public onQuantityChange(id: string): void {
    const changedProduct = this.products.filter((product) => product.id === id)[0];
    if (changedProduct?.qty < 0) {
      changedProduct.qty *= -1;
    } else if (changedProduct?.qty === 0) {
      changedProduct.qty = 1;
    }
    this.quantityChanged.emit();
    this.updatePricePerPieceOnQuantityChange(changedProduct);
  }

  public onIncrementQuantity(id: string): void {
    const changedProduct = this.products.filter((product) => product.id === id)[0];
    changedProduct.qty++;
    this.quantityChanged.emit();
    this.updatePricePerPieceOnQuantityChange(changedProduct);
  }

  public onDecrementQuantity(id: string): void {
    const changedProduct = this.products.filter((product) => product.id === id)[0];
    if (changedProduct.qty >= 2) {
      changedProduct.qty--;
      this.quantityChanged.emit();
    }
    this.updatePricePerPieceOnQuantityChange(changedProduct);
  }

  public updatePricePerPieceOnQuantityChange(changedProduct: CartProductModel): void {
    if (
      changedProduct.availabilityInfo.stockInfo?.remainingStock &&
      changedProduct.qty > changedProduct.availabilityInfo.stockInfo?.remainingStock
    ) {
      toastError(
        `${this.trans('CART.LOCKED_QUANTITY_ERROR')}: ${
          changedProduct.availabilityInfo.stockInfo?.remainingStock
        }`,
      );
      changedProduct.qty = 1;
    } else {
      /*eslint-disable*/
      if (this.shouldShowQuantityDiscount) {
        const productPrice = changedProduct.sale
          ? changedProduct.sale.productPrice
          : changedProduct.originalPricePerItem;
        const quantityDiscountValue =
          changedProduct.discountForAdditionalQuantity?.amount || QUANTITY_DISCOUNT_VALUE;
        if (changedProduct.qty > 1) {
          changedProduct.desiredPrice = this.math.round(
            (productPrice + (productPrice - quantityDiscountValue) * (changedProduct.qty - 1)) /
              changedProduct.qty,
          );
        } else {
          changedProduct.desiredPrice = productPrice;
        }
        this.onDesiredPriceChange();
      }
    }
  }

  /* eslint-enable */
  public bannerDisplayCheck(): void {
    const key = this._localStorageService.getStorage(CART_BANNER_KEY);
    if (key === false) {
      this.bannerEnabled = key;
    }
  }

  public showQuantityDiscountDialog(): void {
    if (this.dialogEnabled && this.shouldShowQuantityDiscount) {
      const dialogRef = this._dialog.open(QuantityDiscountDialogComponent, {
        width: '460px',
      });

      dialogRef.afterClosed().subscribe({
        next: (response) => {
          this._localStorageService.setStorage(CART_DIALOG_KEY, 'false');
          this.dialogDisplayCheck();
        },
      });
    }
  }

  public dialogDisplayCheck(): void {
    const key = this._localStorageService.getStorage(CART_DIALOG_KEY);
    if (key === false) {
      this.dialogEnabled = false;
    }
  }

  public round(value: number) {
    return round(value, 2);
  }
}
